// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'dev',
  production: false,
  ReCaptchaKey: '6LcPGnQmAAAAAOskk-1NV9JHh1yRn0Qx5ikihuer',
  ReCaptchaSecret: '6LcPGnQmAAAAAEoNeApaqk8QuX4zqf9yhmNRPCMp',
  ClientId: 167,
  Endpoints: {
    Api: 'https://prod-api.bookiewiseapi.com',
    Socket: 'https://prod-ws.bwisecon.com',
    ApiDomain: 'bookiewiseapi.com',
    GrossSportsbookDomain: 'https://sportsbook.bookiewiseapi.com',
  },
  DefaultRegistrationEnabled: true,
  FastRegistrationEnabled: false,
  CdnUrl: 'https://d1gnrvs4hpyg5b.cloudfront.net',
  PwaUrl: 'https://dyrpwa1hw35dn.cloudfront.net/',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
